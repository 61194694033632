<!--鉴定机构通用导入-->
<template>
    <!--导入弹窗-->
    <el-dialog
        title="导入材料"
        :visible.sync="centerDialogVisible"
        v-if="centerDialogVisible"
        width="50%"
        center
        :before-close="doPopupClose"
        class="exportdialog"
    >
        <div class="flexdcc">
            <div class="export-box">
                <h1>
                    1
                    <span>选择导入材料类型</span>
                </h1>
                <div>
                    <el-select v-model="attributeKey" size="small" placeholder="请选择" style="margin: 15px 0;width: 240px;">
                        <el-option
                            v-for="item in attributeList"
                            :key="item.attributeKey"
                            :label="item.attributeLabel"
                            :value="item.attributeKey">
                        </el-option>
                    </el-select>
                  <div class="searchbox" style="margin-bottom:5px" v-if="attributeKey&&attributeKey != 'SUNREAL_IDENTIFY_IDCARD'">
                    <div title="文件命名方式" class="searchboxItem ci-full">
                      <span class="itemLabel">文件命名方式：</span>
                      <el-checkbox-group v-model="fileNameMethods" :min="1">
                        <el-checkbox label="10">姓名</el-checkbox>
                        <el-checkbox label="20">身份证号</el-checkbox>
                        <!--<el-checkbox label="30" title="当下载内容大于一项时，此项必须选中" :disabled="attributeIds.length > 1">证件名称</el-checkbox>-->
                      </el-checkbox-group>
                      <span style="margin-left: 50px" v-if="fileNameMethods.includes('10') && !fileNameMethods.includes('20')">
                          系统无法识别重名的人员，请谨慎使用此种方式，如果有重名的学员，建议单独上传
                        </span>
                    </div>
                  </div>
                  <div class="searchbox" style="margin-bottom:5px" v-if="attributeKey&&attributeKey != 'SUNREAL_IDENTIFY_IDCARD'">
                    <div title="文件命名间隔符号" class="searchboxItem ci-full">
                      <span class="itemLabel">文件命名间隔符号：</span>
                      <el-radio-group v-model="fileNameSymbol">
                        <el-radio label="_">下划线（ _ ）</el-radio>
                        <el-radio label="-">中线（ - ）</el-radio>
                      </el-radio-group>
                    </div>
                  </div>
                  <div class="searchbox" style="margin-bottom:15px" v-if="attributeKey&&attributeKey != 'SUNREAL_IDENTIFY_IDCARD'">
                    <div title="文件名增加后缀" class="searchboxItem">
                      <span class="itemLabel">文件名增加后缀：</span>
                      <el-input v-model="fileNameSuffix" size="mini" style="width: auto"  >
                      </el-input>
                      <span style="margin-left: 50px">
                                                  示例：
                          {{ fileNameMethods.filter(item=>item=='10').length>0?'李某某':''}}
                          {{ fileNameSymbol == '_'&&fileNameMethods.length==2?'_':fileNameSymbol == '-'&&fileNameMethods.length==2?'-':'' }}
                          {{ fileNameMethods.filter(item=>item=='20').length>0?'130323********1455':''}}
                          {{ fileNameSymbol == '_'&&fileNameSuffix?'_':fileNameSymbol == '-'&&fileNameSuffix?'-':'' }}
                          {{ fileNameSuffix || '' }}
                      </span>
                    </div>
                  </div>
                    <p style="color: #F56C6C" v-if="attributeKey == 'SUNREAL_IDENTIFY_IDCARD'">1.上传后缀为.zip格式的压缩包文件，将图片直接添加到压缩包内，不要先放在文件夹再添加到压缩包内；<br/>2.上传压缩包最大限制100M，如文件超过100M，请分成多个压缩包分批导入；<br/>3.压缩包内文件为图片格式，请同时上传身份证头像面和身份证国徽面，分别以【身份证号_01】和【身份证号_02】命名，例如：610101190001010151_01.jpg、610101190001010151_02.jpg。</p>
                    <p style="color: #F56C6C" v-if="attributeKey&&attributeKey != 'SUNREAL_IDENTIFY_IDCARD'">1.上传后缀为.zip格式的压缩包文件，将图片直接添加到压缩包内，不要先放在文件夹再添加到压缩包内；<br/>2.上传压缩包最大限制100M，如文件超过100M，请分成多个压缩包分批导入；<br/>3.压缩包内文件为图片格式，按自定义规则命名。</p>

                </div>
            </div>
            <div class="export-box">
                <h1>
                    2
                    <span>导入打包好的Zip压缩包</span>
                </h1>
                <div class="flexdc">
                    <div class="df" style="margin: 20px 0 10px">
                        <el-upload
                            class="upload-demo upload-workers"
                            :action="actionUrl"
                            :on-error="handleError"
                            :on-success="handleSuccess"
                            :on-change="uploadChange"
                            :show-file-list="false"
                            :auto-upload="false"
                        >
                            <el-button class="bgc-bv" style="font-size: 12px; width: 80%"
                            >浏览</el-button
                            >
                        </el-upload>
                        <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                            当前选择文件：
                            <span style="color: #f46173">{{ fileName }}</span>
                        </p>
                        <p v-else style="margin-top: 10px; margin-left: 10px">
                            未选择文件
                        </p>
                    </div>
                    <div v-if="complete > 0">
                      <el-progress
                        :text-inside="true"
                        :stroke-width="20"
                        :percentage="complete"
                      ></el-progress>
                    </div>
                    <div>
                        <el-button
                            class="bgc-bv"
                            size="mini"
                            style="margin-top: 10px; height: 35px"
                            :disabled="doExportInDisabled"
                            @click="doExport"
                        >开始导入</el-button
                        >
                    </div>
                    <div style="margin-top: 10px" v-if="progressVal > 0">
                        <el-progress
                            :text-inside="true"
                            :stroke-width="20"
                            :percentage="progressVal"
                        ></el-progress>
                    </div>
                </div>
            </div>
            <div class="export-box">
                <h1>
                    3
                    <span>导入结果</span>
                </h1>
                <div class="df studentNum">
                    <span>材料总数量:{{ totalNum }}份;</span>
                    <span>成功:{{ correctNum }}份;</span>
                    <span>失败:{{ errorNum }}份;</span>
                </div>
                <div>
                    <el-button
                        class="bgc-bv"
                        style="margin-top: 15px; height: 35px"
                        size="mini"
                        :disabled="errorNum == '0'"
                        @click="doExportError"
                    >导出错误数据</el-button
                    >
                </div>
                <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
                    有导入失败材料，请导出错误数据，将导入失败材料调整后重新上传
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "accreditationAgencyImport",
    // businessType // 导入业务类型标识 [ BUSINESS_TYPE ]
    // importType // 导入类型 学员
    // unit // 单位 学员-人
    props: [
        "businessType","importType","unit"
    ],
    components: {
    },
    data() {
        return {
            // 导入
            centerDialogVisible:false,
            totalNumlb: 0,
            correctNumlb: 0,
            errorNumlb: 0,
            progressVal: 0,
            batchId: "",
            fileName: "",
            fileKey: "",
            excelUrl: "",
            doExportInDisabled: false, //开始导入按钮禁用
            fileNameMethods: ['20'],
            fileNameSymbol: '_',
            attributeKey: '',
            attributeList: [],
            fileNameSuffix: '',
            complete:0,
        };
    },
    // watch: {
    //   editPopupData: {
    //     //深度监听，可监听到对象、数组的变化
    //     handler(val, oldVal) {
    //       // 200-安全培训教育制度；

    //     },
    //     immediate: true,
    //     deep: true, //true 深度监听
    //   },
    // },
    computed: {
        ...mapGetters({
            downloadItems: "getDownloadItems",
        }),
    },
    methods: {
        /* 导入 */
        showPopup(businessId) {
            this.businessId = businessId
            this.totalNum = "0";
            this.errorNum = "0";
            this.correctNum = "0";
            this.batchId = "";
            this.getAttributeList();
            this.centerDialogVisible = true;
        },
        /* 导入 */
        downloadExcelgs(templatePath) {
            /**
             * 下载模板
             * @param param 参数
             */
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = templatePath;
            let templateNames = templatePath.slice(8);
            console.log(templateNames);
            link.setAttribute("download", templateNames);
            document.body.appendChild(link);
            link.click();
        },
        // 导入学员/* 导入 */
        uploadChange(file) {
            this.fileName = file.name;
            let size = file.size / 1024 / 1024;
            // let fileName = file.name.split(".");
            let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isZIP = extension === "ZIP";
            const iszip = extension === "zip";

            if (!isZIP && !iszip) {
                this.$message.error("只能上传后缀是.zip的文件");
                this.fileName = ''
                return;
            }
            if (size > 100) {
              this.$message.error("文件大小不能超过100M");
              this.fileName = ''
              return;
            }
            let formData = new FormData();
            formData.append("folder ", "TEMP");
            formData.append("file ", file.raw);
            formData.append("fileType ", extension);

            this.$Postformat("/sys/file/async/upload", formData,false,0,6,{
              onUploadProgress: progressEvent => {
                let complete = ~~((progressEvent.loaded / progressEvent.total) * 100/2);
                console.log(complete);
                this.complete = complete
              }
              })
                .then((result) => {
                  this.getUploadProcess(result.data)
                })
                .catch((err) => {
                    return;
                });
        },
        // 获取进度
        getUploadProcess (batchId) {
          console.log(batchId);
          this.$post('/sys/file/async/upload/progress', { batchId },true,3000,6)
            .then((res) => {
              console.log(res)
              const { progress, fileKey,status } = res.data
              if (res.status=='0') {
                if(progress==100&&fileKey&&status){
                  console.log('成功');
                  this.complete = progress
                  this.fileKey = fileKey
                }else{
                  this.complete = progress
                  setTimeout(() => {
                    this.getUploadProcess(batchId);
                  }, 2000);
                }
              } else {
                if (res.message) {
                  this.$message({
                    type: "error",
                    message: res.message,
                  });
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        },
        /* 导入 */
        doExport() {
            if (this.fileKey == "") {
                this.$message({
                    message: this.complete>0?"请等待文件上传完成":"请选择文件",
                    type: "warning",
                });
            } else {
                if(!this.attributeKey){
                    this.$message.warning("请选择材料类型");
                    return;
                }
                // this.$confirm("是否将此表中学员绑定培训任务?", "提示", {
                //     confirmButtonText: "确定",
                //     confirmButtonClass: "confirmButtonClass",
                //     cancelButtonText: "取消",
                //     type: "warning",
                // })
                //     .then(() => {
                        this.doExportInDisabled = true;
                        if (this.fileKey) {
                            const parmar = {
                                projectId: this.businessId,
                                attributeKey: this.attributeKey,
                                fileKey: this.fileKey,
                            };
                            if (this.fileNameMethods.includes('10')) {
                              parmar.name = true;
                            }
                            if (this.fileNameMethods.includes('20')) {
                              parmar.idcard = true;
                            }
                            if (this.fileNameSuffix) {
                              parmar.suffix = this.fileNameSuffix;
                            }
                            if (this.fileNameSymbol) {
                              parmar.split = this.fileNameSymbol;
                            }
                            console.log(parmar)
                            this.$post("/biz/apply/import/materialsImport", parmar, 5000,true,6)
                                .then((res) => {
                                    if (res.status == 0) {
                                        if (res.status == 0) {
                                            console.log(res.data);
                                            this.doProgress(res.data);
                                        }
                                    }
                                })
                                .catch(() => {
                                    return;
                                });
                        } else {
                            this.$message({
                                message: "请选择文件",
                                type: "warning",
                            });
                        }
                    // })
                    // .catch(() => {
                    //     return;
                    // });
            }
        },
        /* 导入 */
        //获取导入进度
        /* /biz/projectUser/importProgress */
        doProgress(batchId) {
            this.$post(
                "/biz/apply/import/importMaterialsProgress",
                {
                    batchId,
                },
                5000,true,6
            ).then((ret) => {
                console.log(ret)
                if (!ret.data.status) {
                    this.progressVal = ret.data.progress;
                    setTimeout(() => {
                        this.doProgress(batchId);
                    }, 2000);
                } else {
                    if (ret.data.message) {
                        this.$message({
                            type: "error",
                            message: ret.data.message,
                        });
                    } else {
                        this.totalNum = ret.data.totalNum;
                        this.errorNum = ret.data.errorNum;
                        this.correctNum = ret.data.correctNum;
                        this.batchId = ret.data.batchId;
                        this.progressVal = ret.data.progress;
                        this.$message({
                            type: "success",
                            message: "导入成功",
                        });
                    }
                }
            });
        },
        /* 导入 */
        //导出错误数据
        doExportError() {
            // console.log(this.batchId);
            if (this.batchId == "" || this.fileKey == "") {
                this.$message({
                    message: "还没有导入数据，请先导入文档",
                    type: "warning",
                });
            } else {
                this.$post(
                    "/biz/apply/import/exportErrorMaterials",
                    {
                        batchId: this.batchId
                    },
                    5000,true,6
                ).then((res) => {
                    if (res.status == 0) {
                        window.open(res.data);
                    }
                });
            }
        },
        /* 导入 */
        doPopupClose() {
            this.centerDialogVisible = false;
            this.fileKey = "";
            this.fileName = "";
            this.progressVal = 0;
            this.doExportInDisabled = false;

            this.attributeKey = "";
            this.attributeList = []
            this.complete = 0
            this.$emit("eventBus");
            // this.getData();
        },
        /* 导入 */
        getAttributeList() {
            let params = {
                projectId: this.businessId,
            }
            console.log(params)
            this.$post('/biz/apply/import/selectAttributeList', params,3000,false,6).then((ret) => {
                console.log(ret.data)
                this.attributeList = ret.data || [];
                this.attributeList.unshift({
                    attributeKey: 'SUNREAL_IDENTIFY_IDCARD',
                    attributeLabel: '实名身份证'
                })
            });
        },
    },
    mounted() {},
    created() {},
};
</script>

<style lang="less" scoped>
/*导入*/
.exportdialog {
    .el-dialog__title {
        font-size: 16px;
    }
    .export-box {
        width: 100%;
        margin-bottom: 20px;
        h1 {
            padding: 0 5px 10px;
            display: flex;
            align-items: flex-end;
            font-size: 40px;
            border-bottom: 1px dashed #ccc;
            span {
                margin-left: 20px;
                font-size: 14px;
            }
        }
        > div {
            padding-left: 30px;
        }
        .el-button.is-disabled,
        .el-button.is-disabled:focus,
        .el-button.is-disabled:hover {
            color: #fff;
            background: #c3c3c3;
            border: none;
        }
    }
}
.docsDialog {
    .el-form-item {
        margin-bottom: 0.5rem;
    }
}
.studentNum {
    margin: 20px 0 10px;
    span {
        margin: 0 10px;
    }
}
.exportdialog {
    .upload-workers {
        width: 80px;
        height: 35px;
        line-height: 35px;
        /deep/.el-upload {
            height: 35px !important;
            width: 80px;
            min-width: 80px;
            border: none !important;
            .el-button {
                padding: 5px 0;
                width: 100% !important;
            }
        }
    }
}
</style>
