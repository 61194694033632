<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title" v-if="!isStatistics">
                <span>
                    <em>当前位置：</em>
                    <a v-if="!channel" href="javascript:;">报名管理</a>
                    <a v-if="channel" href="javascript:;">招生管理</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">报名列表</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">学员管理</a>
                </span>
            </div>
            <div class="framePage-title" v-if="isStatistics">
                  <span>
                      <em>当前位置：</em>
                      <a href="javascript:;">数据中心</a>
                      <i>></i>
                      <a href="javascript:;" @click="$router.back()">鉴定报名统计</a>
                      <i>></i>
                      <a href="javascript:;" class="cur-a">学员管理</a>
                  </span>
            </div>

            <!-- 主体区域 -->
            <div class="framePage-body">
                <div style="margin-left:10px;margin-bottom:20px;">招生任务名称:{{projectName}}<span v-if="isStatistics">&nbsp;&nbsp;&nbsp;&nbsp;机构名称:{{compName}}</span></div>
                <!-- 搜索区域 -->
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <div class="searchbox">
                        <div title="姓名" class="searchboxItem ci-full">
                            <span class="itemLabel">姓&emsp;&emsp;名:</span>
                            <el-input v-model="searchForm.userName" placeholder="请输入姓名" type="text" clearable size="small"/>
                        </div>
                        <div title="联系方式" class="searchboxItem ci-full">
                            <span class="itemLabel">联系方式:</span>
                            <el-input v-model="searchForm.mobile" placeholder="请输入联系方式" type="text" clearable size="small"/>
                        </div>
                        <div v-if="!channel" title="招生渠道" class="searchboxItem ci-full">
                            <span class="itemLabel">招生渠道:</span>
                            <el-select size="small" clearable="" v-model="searchForm.channelId" placeholder="请选择">
                                <el-option
                                    v-for="item in recruitmentChannelsList"
                                    :key="item.channelId"
                                    :label="item.contactUser"
                                    :value="item.channelId">
                                </el-option>
                            </el-select>
                        </div>
                        <div title="审核状态" class="searchboxItem ci-full">
                            <span class="itemLabel">审核状态:</span>
                            <el-select size="small" clearable v-model="searchForm.auditState" placeholder="请选择">
                                <el-option
                                    v-for="item in auditStateList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="searchboxItem ci-full">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                        </div>
                        <div class="searchboxItem ci-full">
                            <el-button v-if="!channel" type="primary" class="bgc-bv" round @click="exportData()">导出</el-button>
                        </div>
                        <template  v-if="!isStatistics">
                            <div class="searchboxItem ci-full">
                                <el-button v-if="!channel" type="primary" class="bgc-bv" round @click="handleAdd">新增</el-button>
                            </div>
                            <div class="searchboxItem ci-full">
                                <el-button type="primary" class="bgc-bv" round @click="Exportstudent">导入</el-button>
                            </div>
                            <div class="searchboxItem ci-full">
                                <el-button type="primary" class="bgc-bv" round @click="ExportsMaterials">导入材料</el-button>
                            </div>
                        </template>

                    </div>
                </div>
                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="listData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                :index="indexMethod"
                                type="index"
                                width="50"
                            />
                            <el-table-column
                                label="姓名"
                                prop="userName"
                            />
                            <el-table-column
                                label="身份证号"
                                prop="idcard"
                            />
                            <el-table-column
                                label="联系方式"
                                prop="mobile"
                            />
                            <el-table-column
                                label="报名方式"
                            >
                                <template v-slot="{row}">
                                    {{$setDictionary("CT_JOIN_TYPE", row.joinType)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="招生渠道"
                                prop="channelName"
                            />
                            <el-table-column
                                label="审核状态"
                            >
                                <template v-slot="{row}">
                                    {{$setDictionary("CT_USER_AUDIT_STATE", row.auditState)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="备注"
                                prop="auditReason"
                            />
                            <el-table-column
                                label="操作"
                                min-width="110px"
                            >
                                <template v-slot="{row, $index}">
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="editStudent(row, $index)">编辑</el-button>
                                    <el-button v-if="!isStatistics" type="text" size="mini" style="padding: 0 5px;" @click="deleteStudent(row)">删除</el-button>
                                    <el-button v-if="row.auditState === '20'&&!isStatistics" type="text" size="mini"
                                               style="padding: 0 5px;color: red" @click="toDetail(row, $index)">审核</el-button>
                                    <el-button v-if="isStatistics||row.auditState != '20'" type="text" size="mini" style="padding: 0 5px;" @click="toDetail(row, $index)">详情</el-button>
                                    <el-button
                                        v-if="!channel"
                                        type="text" size="mini" style="padding: 0 5px;" @click="setAddAndEdit(row, $index)">进度设置</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>


        </div>

        <!--新增弹窗-->
        <el-dialog
            title="添加报考学员信息"
            :before-close="closeAddDialog"
            :visible.sync="addDialogVisible"
            width="60%"
            center
            :close-on-click-modal="false"
        >
            <div class="addDialog">
                <el-form
                    ref="addform"
                    :model="addform"
                    :rules="addRules"
                    label-position="left"
                    label-width="100px"
                >
                    <el-form-item label="姓名：" prop="name">
                        <el-input
                            type="text"
                            v-model="addform.name"
                            clearable
                            placeholder="请输入姓名"
                            size="small"
                        />
                    </el-form-item>
                    <!--<el-form-item label="证件类型：" prop="certificatesType">-->
                    <!--    <el-select-->
                    <!--        v-model="addform.certificatesType"-->
                    <!--        placeholder="请选择"-->
                    <!--        size="small"-->
                    <!--        clearable-->
                    <!--    >-->
                    <!--        <el-option-->
                    <!--            v-for="item in evaluateIdTypeList"-->
                    <!--            :key="item.value"-->
                    <!--            :label="item.label"-->
                    <!--            :value="item.value"-->
                    <!--        >-->
                    <!--        </el-option>-->
                    <!--    </el-select>-->
                    <!--</el-form-item>-->
                    <el-form-item label="证件号码：" prop="certificatesCode">
                        <el-input
                            type="text"
                            v-model="addform.certificatesCode"
                            clearable
                            placeholder="请输入证件号码"
                            size="small"
                        />
                    </el-form-item>
                    <!--<el-form-item label="文化程度：" prop="educationLevel">-->
                    <!--    <el-select-->
                    <!--        v-model="addform.educationLevel"-->
                    <!--        placeholder="请选择"-->
                    <!--        clearable-->
                    <!--        filterable-->
                    <!--        size="small"-->
                    <!--    >-->
                    <!--        <el-option-->
                    <!--            v-for="item in educationList"-->
                    <!--            :key="item.value"-->
                    <!--            :label="item.label"-->
                    <!--            :value="item.value"-->
                    <!--        >-->
                    <!--        </el-option>-->
                    <!--    </el-select>-->
                    <!--</el-form-item>-->
                    <!--<el-form-item label="所在单位：" prop="workUnit">-->
                    <!--    <el-input-->
                    <!--        type="text"-->
                    <!--        v-model="addform.workUnit"-->
                    <!--        clearable-->
                    <!--        placeholder="请输入所在单位"-->
                    <!--        size="small"-->
                    <!--    />-->
                    <!--</el-form-item>-->
                    <el-form-item label="手机号码：" prop="mobile">
                        <el-input
                            type="text"
                            v-model="addform.mobile"
                            clearable
                            placeholder="请输入手机号码"
                            size="small"
                        />
                    </el-form-item>
                    <!--<el-form-item label="申报条件：" prop="conditionId">-->
                    <!--    <el-select-->
                    <!--        v-model="addform.conditionId"-->
                    <!--        placeholder="请选择"-->
                    <!--        clearable-->
                    <!--        filterable-->
                    <!--        value-key="conditionId"-->
                    <!--        size="small"-->
                    <!--        @change="conditionChange"-->
                    <!--    >-->
                    <!--        <el-option-->
                    <!--            v-for="item in conditionIdTypeList"-->
                    <!--            :key="item.conditionId"-->
                    <!--            :label="item.conditionName"-->
                    <!--            :value="item.conditionId"-->
                    <!--        >-->
                    <!--        </el-option>-->
                    <!--    </el-select>-->
                    <!--</el-form-item>-->
                    <!--<el-row>-->
                    <!--    <el-col :span="12">-->
                    <!--        <el-form-item label="上传材料" prop="idCardFront">-->
                    <!--            <el-upload-->
                    <!--                :on-change="handleAvatarSuccessCardFront"-->
                    <!--                :before-upload="$beforeAvatarUpload"-->
                    <!--                :http-request="$requestMine"-->
                    <!--                :show-file-list="false"-->
                    <!--                class="img-el-upload"-->
                    <!--                action-->
                    <!--                accept="image/png, image/gif, image/jpg, image/jpeg"-->
                    <!--            >-->
                    <!--                <el-image-->
                    <!--                    :src="-->
                    <!--            addform.idCardFront ||-->
                    <!--            require('@/assets/develop.png')-->
                    <!--          "-->
                    <!--                    fit="contain"-->
                    <!--                    class="imgCenter"-->
                    <!--                ></el-image>-->
                    <!--            </el-upload>-->
                    <!--            <p class="PImgtitle">人像面</p>-->
                    <!--        </el-form-item>-->
                    <!--    </el-col>-->
                    <!--    <el-col :span="12">-->
                    <!--        <el-form-item label="" prop="idCardFront">-->
                    <!--            <el-upload-->
                    <!--                :on-change="handleAvatarSuccessCardFront"-->
                    <!--                :before-upload="$beforeAvatarUpload"-->
                    <!--                :http-request="$requestMine"-->
                    <!--                :show-file-list="false"-->
                    <!--                class="img-el-upload"-->
                    <!--                action-->
                    <!--                accept="image/png, image/gif, image/jpg, image/jpeg"-->
                    <!--            >-->
                    <!--                <el-image-->
                    <!--                    :src="-->
                    <!--            addform.idCardFront ||-->
                    <!--            require('@/assets/develop.png')-->
                    <!--          "-->
                    <!--                    fit="contain"-->
                    <!--                    class="imgCenter"-->
                    <!--                ></el-image>-->
                    <!--            </el-upload>-->
                    <!--            <p class="PImgtitle">国徽面</p>-->
                    <!--        </el-form-item>-->
                    <!--    </el-col>-->
                    <!--</el-row>-->
                    <!--<el-form-item label="学历证书：" prop="headPathUrl">-->
                    <!--    <el-upload-->
                    <!--        :on-change="handleAvatarSuccess2"-->
                    <!--        :before-upload="$beforeAvatarUpload"-->
                    <!--        :http-request="$requestMine"-->
                    <!--        :show-file-list="false"-->
                    <!--        class="img-el-upload"-->
                    <!--        action-->
                    <!--        accept="image/png, image/gif, image/jpg, image/jpeg"-->
                    <!--    >-->
                    <!--        <el-image-->
                    <!--            :src="-->
                    <!--        addform.headPathUrl ||-->
                    <!--        require('@/assets/develop.png')-->
                    <!--      "-->
                    <!--            fit="contain"-->
                    <!--            class="imgCenter"-->
                    <!--        ></el-image>-->
                    <!--    </el-upload>-->
                    <!--</el-form-item>-->
                    <div v-for="(item,index) in attributes" :key="item.attributeId">
                        <!--文本-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 1" :class="item.requiredField?'hasRequired':''">
                            <el-input
                                style="width: 100%;"
                                size="small"
                                v-model="item.attributeValue"
                                :placeholder="'请输入'+item.attributeLabel">
                            </el-input>
                        </el-form-item>

                        <!--下拉-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 3" :class="item.requiredField?'hasRequired':''">
                            <el-select
                                style="width: 100%;"
                                size="small"
                                v-model="item.attributeValue"
                                :placeholder="'请选择'+item.attributeLabel">
                                <el-option
                                    v-for="itemInner in item.options"
                                    :key="itemInner.value"
                                    :label="itemInner.label"
                                    :value="itemInner.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!--时间-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 4" :class="item.requiredField?'hasRequired':''">
                            <el-date-picker
                                style="width: 220px;"
                                type="date"
                                v-model="item.attributeValue"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                :placeholder="'请选择'+item.attributeLabel"
                                size="small"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <!--行政区划 - 省市区-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 5" :class="item.requiredField?'hasRequired':''">
                            <el-cascader
                                style="width: 100%"
                                clearable
                                filterable
                                v-model="item.attributeValue"
                                :options="areatreeList"
                                :placeholder="'请选择'+item.attributeLabel"
                                :props="propsarea"
                                size="small"
                            ></el-cascader>
                        </el-form-item>
                        <!--整数-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 6" :class="item.requiredField?'hasRequired':''">
                            <el-input-number
                                style="width: 100%;"
                                v-model="item.attributeValue"
                                :step="1"
                                :precision="0"
                                :controls="false"
                                :placeholder="'请输入'+item.attributeLabel"
                                :min="0"
                                size="small">
                            </el-input-number>
                        </el-form-item>
                        <!--小数-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 7" :class="item.requiredField?'hasRequired':''">
                            <el-input-number
                                style="width: 100%;"
                                v-model="item.attributeValue"
                                :step="1"
                                :precision="3"
                                :controls="false"
                                :placeholder="'请输入'+item.attributeLabel"
                                :min="0"
                                size="small">
                            </el-input-number>
                        </el-form-item>
                        <!--申报条件-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 8" :class="item.requiredField?'hasRequired':''">
                            <el-select
                                style="width: 100%;"
                                size="small"
                                v-model="item.attributeValue"
                                popper-class="my-select"
                                @focus="setOptionWidth"
                                :placeholder="'请选择'+item.attributeLabel">
                                <el-option
                                    v-for="itemInner in declarationConditions"
                                    :key="itemInner.conditionCode"
                                    :label="itemInner.description"
                                    :style="{width: selectOptionWidth, 'min-width': '350px'}"
                                    :value="itemInner.conditionCode">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <!--身份证号码-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 9" :class="item.requiredField?'hasRequired':''">
                            <el-input
                                style="width: 100%;"
                                size="small"
                                v-model="item.attributeValue"
                                :placeholder="'请输入'+item.attributeLabel">
                            </el-input>
                        </el-form-item>
                        <!--行政区划 - 省市-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 11" :class="item.requiredField?'hasRequired':''">
                            <el-cascader
                                style="width: 100%;"
                                clearable
                                filterable
                                v-model="item.attributeValue"
                                :options="areatreeList2"
                                :placeholder="'请选择'+item.attributeLabel"
                                :props="propsarea"
                                size="small"
                            ></el-cascader>
                        </el-form-item>

                        <!--行政区划 - 省-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 10" :class="item.requiredField?'hasRequired':''">
                            <el-cascader
                                style="width: 100%;"
                                clearable
                                filterable
                                v-model="item.attributeValue"
                                :options="areatreeList3"
                                :placeholder="'请选择'+item.attributeLabel"
                                :props="propsarea"
                                size="small"
                            ></el-cascader>
                        </el-form-item>
                        <!--上传-->
                        <el-form-item :label="item.attributeLabel+'：'" v-if="item.attributeType == 2" :class="item.requiredField?'hasRequired':''">
                            <el-upload
                                :on-change="(file,fileList)=>handlebusinessLicense2(file,item.attributeId,index,(item.attributeId=='-4'||item.attributeId=='-5')?'2':'1')"

                                :before-upload="$beforeAvatarUpload"
                                :http-request="$requestMine"
                                :show-file-list="false"
                                class="img-el-upload"
                                action
                                accept="image/png, image/gif, image/jpg, image/jpeg"
                            >
                                <img :src="item.attributeValue|| require('@/assets/develop.png')" alt="" style="margin: 0 auto;">
                            </el-upload>
                        </el-form-item>
                    </div>

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer" style="width: 100%">
                <el-button @click="closeAddDialog">取 消</el-button>
                <el-button type="primary" @click="doAdd" class="bgc-bv"
                >确 定</el-button>
            </span>
        </el-dialog>
        <!--导入材料-->
        <importMaterials
            ref="importMaterials"
            @eventBus="importMaterialsBack"
        />

        <!--鉴定机构通用导入-->
        <!-- businessType  导入业务类型标识 [ BUSINESS_TYPE ]-->
        <!-- importType  导入类型 学员-->
        <!-- unit  单位 学员-人-->
        <!--fileType  EXCEL  ZIP-->
        <accreditationAgencyImport
            ref="accreditationAgencyImport"
            @eventBus="accreditationAgencyImportBack"
            businessType="CT_APPLY_USER_IMPORT"
            importType="学员"
            unit="人"
            fileType="EXCEL"
        />
        <el-dialog title="进度设置" :visible.sync="addAndEditData.dialogVisible" width="500px" :destroy-on-close="true">
            <el-form ref="addAndEditData" class="addAndEditData" :model="addAndEditData" :rules="addAndEditDataRules"
                label-width="120px" size="small" :before-close="cancelData">
                <el-form-item label="报考进度" prop="applyState">
                <el-select v-model="addAndEditData.applyState" placeholder="请选择报考进度" size="small" clearable>
                    <el-option v-for="item in applyStateList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="bgc-bv" size="small" @click="cancelData">取消</el-button>
                <el-button type="primary" class="bgc-bv" size="small" @click="saveData">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
// 鉴定机构通用导入
import accreditationAgencyImport from "@/components/accreditationAgencyImport.vue";
// 导入材料
import importMaterials from "@/views/institutionalManagement/registrationManagement/importMaterials.vue";
export default {
    name:"studentManagement",
    components:{
        Empty,
        PageNum,
        // 鉴定机构通用导入
        accreditationAgencyImport,
        // 导入材料
        importMaterials
    },
    mixins:[List],
    data(){
        var projectUserPhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error("请输入正确的手机号码"));
            } else if (
                value &&
                !/^1[3456789]\d{9}$/.test(value) &&
                !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
            ) {
                callback(new Error("请输入正确的手机号码"));
            } else {
                callback();
            }
        };
        return{
            selectOptionWidth:'',
            // 搜索表单
            searchForm:{
                userName:"",//姓名
                mobile:"",//联系方式
                channelId:"",//招生渠道
                auditState: "",
                projectId: null,
                confessMark:"10" // 自招传的属性
            },
            // 分页器数据
            pagination:{
                total: 0,//总条数
                size: 10,//每页几条数据
                current: 1//当前页
            },
            //表格数据
            listData:[],
            // 招生渠道数据
            recruitmentChannelsList:[],
            // 培训任务id(路由传参而来)
            projectId:"",
            // 培训任务名称(路由传参而来)
            projectName:"",
            auditStateList: [],
            channel: false,
            isStatistics: false,
            compName:'',
            compId:'',

            // 新增弹层
            addDialogVisible: false,
            addform: {
                name: '',// 姓名
                // certificatesType: '', // 证件类型
                certificatesCode: '', // 证件号码
                // educationLevel: '', // 文化程度
                // workUnit: '', // 所在单位
                mobile: '', // 手机号码
                // conditionId: '', // 申报条件
                // idCardFront: '', // 身份证人像面
                // idCardBack: '', // 身份证国徽面
                // headPathUrl: '', // 学历证书
            },
            evaluateIdTypeList: [],// 证件类型下拉列表
            educationList: [],// 文化程度下拉列表
            conditionIdTypeList: [], //申报条件下拉数据
            addRules: {
                name: [
                    { required: true, message: "请输入姓名", trigger: "blur" },
                ],
                // certificatesType: [
                //     { required: true, message: "请选择证件类型", trigger: "change" },
                // ],
                certificatesCode: [
                    { required: true, message: "请输入证件号码", trigger: "blur" },
                ],
                // educationLevel: [
                //     { required: true, message: "请选择文化程度", trigger: "change" },
                // ],
                // workUnit: [
                //     { required: true, message: "请输入所在单位", trigger: "blur" },
                // ],
                mobile: [
                    { required: true, validator: projectUserPhone, trigger: "blur" },
                ],
                // conditionId: [
                //     { required: true, message: "请选择申报条件", trigger: "change" },
                // ],
                // idCardFront: [
                //     { required: true, message: "请上传身份证人像面", trigger: "change" },
                // ],
                // idCardBack: [
                //     { required: true, message: "请上传身份证国徽面", trigger: "change" },
                // ],
                // headPathUrl: [
                //     { required: true, message: "请上传学历证书", trigger: "change" },
                // ],
            },


            // 导入
            centerDialogVisible:false,
            totalNumlb: 0,
            correctNumlb: 0,
            errorNumlb: 0,
            progressVal: 0,
            batchId: "",
            templatelist: "", // 模板数据
            fileName: "",
            fileKey: "",
            excelUrl: "",
            importType: "",
            doExportInDisabled: false, //开始导入按钮禁用
            trainTaskInfo:{},
            ignorevalidity: false, //忽略手机号有效性检测

            // // 上传材料
            // materialsDialogVisible: false,
            // currentIndex: '',
            // materials: [
            //     {
            //         id: '1',
            //         type: '10',
            //         name: '身份证人像面',
            //         successNum: 0,
            //         errorNum: 0,
            //         fileUrl: '',
            //         fileKey: ''
            //     },
            //     {
            //         id: '2',
            //         type: '20',
            //         name: '身份证国徽面',
            //         successNum: 0,
            //         errorNum: 0,
            //         fileUrl: '',
            //         fileKey: ''
            //     },
            //     {
            //         id: '3',
            //         name: '毕业证书',
            //         successNum: 0,
            //         errorNum: 0,
            //         fileUrl: '',
            //         fileKey: ''
            //     }
            // ]
            // 根据报名id获取学员新增属性
            attributes:[],

            // 行政区划 - 下拉数据 - 省市区
            areatreeList: [],
            // 行政区划 - 下拉数据 - 省市
            areatreeList2: [],
            // 行政区划 - 下拉数据 - 省
            areatreeList3: [],
            // 行政区划 - 规定字段
            propsarea: {
                value: "value",
                label: "label",
                emitPath: false,
                // checkStrictly: true,
            },
            // 申报条件 - 下拉数据
            declarationConditions: [],
            // 报考进度 - 下拉数据
            applyStateList: [],
            // 新增&编辑课程 - 弹框
            addAndEditData: {
                dialogVisible: false, // 弹框显示
                projectApplyIdList: [], // 学员申请id集合
                applyState: "", // 报考进度
            },
        }


    },
    computed: {
        ...mapGetters({
            downloadItems: "getDownloadItems",
        }),
    },
    methods:{
        setOptionWidth(event) {
// 下拉框弹出时，设置弹框的宽度
            this.$nextTick(() => {
                this.selectOptionWidth = event.srcElement.offsetWidth + 'px'
            })
        },
        getUserAttributeInfo(){
            let _this = this
            let params = {
                projectId: this.projectId
            }
            this.$post("/biz/apply/user/getUserAttributeInfo",params,3000,true,6).then((ret) => {
                if(ret.status == 0){
                  console.log(ret);
                    _this.attributes = []
                    ret.data.attributes.forEach((e,i)=>{
                        let item = {
                            attributeId: e.attributeId,
                            attributeKey: e.attributeKey,
                            attributeLabel: e.attributeLabel,
                            attributeType: e.attributeType,
                            requiredField: e.requiredField,
                            attributeValue: ''
                        }
                        if(e.attributeType == 2){
                            // 如果是下拉
                            item.fileKey = ''
                        }
                        if(e.attributeType == 6||e.attributeType == 7){
                            // 如果是下拉
                            item.attributeValue = undefined
                        }
                        if(e.attributeType == 3){
                            // 如果是下拉
                            item.options = _this.selectGetList(e.dictionaryKey)
                        }
                        _this.attributes.push(item)
                    })
                }
            });
        },
        // 参数下拉数据获取
        selectGetList(keyName){
            const listData = this.$setDictionary(keyName, "list");
            let list = []
            for (const key in listData) {
                list.push({
                    value: listData[key],
                    label: listData[key],
                });
            }
            return list
        },
        // 报考进度 数据获取
        getInvoice_type(){
            let arr2 = this.$setDictionary("CT_USER_APPLY_STATE", "list");
            for (const key in arr2) {
                this.applyStateList.push({
                label: arr2[key],
                value: key
                });
            }
        },
        // 行政区划 - 下拉数据
        getareatree() {
            this.$post("/sys/area/treeLabel").then((ret) => {
                //省市县
                this.areatreeList = ret.data;
                // 省市
                this.areatreeList2 = []
                let list2 = []
                ret.data.forEach((e,i)=>{
                    let item = {
                        value: e.value,
                        label: e.label,
                        children: []
                    }
                    if(e.children&&e.children.length>0){
                        e.children.forEach((eInner,inner)=>{
                            let itemInner = {
                                value: eInner.value,
                                label: eInner.label
                            }
                            item.children.push(itemInner)
                        })
                    }else{
                        item.children = null
                    }

                    list2.push(item)
                })
                this.areatreeList2 = list2
                // 省
                this.areatreeList3 = []
                let list3 = []
                ret.data.forEach((e,i)=>{
                    let item = {
                        value: e.value,
                        label: e.label,
                    }
                    list3.push(item)
                })
                this.areatreeList3 = list3
            });
        },
        // 申报条件下拉数据获取
        getDeclarationConditions(){
            this.$post("/biz/apply/user/selectDeclarationCondition",{projectId: this.projectId},3000,true,6).then((ret) => {
                this.declarationConditions = ret.data;
            });
        },
        // 上传
        handlebusinessLicense2(res, attributeId, index,type=1) {

            const isLt10M = res.size / 1024 / 1024 < 10;
            if (!isLt10M) {
                this.$message.error("上传图片大小不能超过 10MB!");
            } else if (
                ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
                    res.raw.type
                ) === -1
            ) {
                this.$message.error("请上传JPG/PNG/JPEG格式文件");
                return false;
            } else {
              if(type==1){
                this.uploadFetch(res.raw,attributeId,index);
              }else if(type==2){
                this.uploadFetch2(res.raw,attributeId,index);
              }
            }

        },
        uploadFetch(file,attributeId,index) {
            let _this = this;
            const fileType = file.name.slice(
                file.name.lastIndexOf(".") + 1,
                file.name.length
            );
            let formData = new FormData();
            formData.append("file", file);
            formData.append("fileType", fileType);
            formData.append("folder", "LECTURER");
            formData.append("attributeId", attributeId);
            this.$Postformat("/sys/upload/uploadAttribute", formData).then((ret) => {
                _this.attributes[index].attributeValue = ret.data.fileUrl || "";
                _this.attributes[index].fileKey = ret.data.fileKey || "";
                console.log(_this.attributes[index]);
            });
        },
        // 身份证正反面上传
        uploadFetch2(file,attributeId,index) {
            let _this = this;
            const fileType = file.name.slice(
                file.name.lastIndexOf(".") + 1,
                file.name.length
            );
            let formData = new FormData();
            formData.append("file", file);
            formData.append("fileType", fileType);
            formData.append("folder", "LECTURER");
            this.$Postformat("/sys/upload", formData).then((ret) => {
                _this.attributes[index].attributeValue = ret.data.fileURL || "";
                _this.attributes[index].fileKey = ret.data.fileKey || "";
            });
        },
        //点击新增按钮
        handleAdd(){
            this.addDialogVisible = true
            // 根据报名id获取学员新增属性
            this.getUserAttributeInfo()
        },
        // 关闭新增弹层
        closeAddDialog(){
            this.addDialogVisible = false
            this.addform.name = ''
            this.addform.certificatesCode = ''
            this.addform.mobile = ''
            this.attributes = []
        },
        // 提交新增
        doAdd(){
            this.$refs.addform.validate((valid) => {
                    if (valid) {
                        console.log(this.attributes)
                        // 校验
                        try {
                            // 身份证正则
                            let regSFZ = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/
                            this.attributes.forEach((e,i)=>{
                                if(e.requiredField&&!e.attributeValue){
                                    this.$message.error(e.attributeLabel+'不能为空')
                                    throw Error();
                                }
                                // 如果是身份证号码
                                if(e.attributeValue&&e.attributeType == 9&&!regSFZ.test(e.attributeValue)){
                                    this.$message.error(e.attributeLabel+'的格式不正确')
                                    throw Error();
                                }
                            })
                        } catch (error) {
                            return
                        }
                        // 数据传参赋值
                        let params = {
                            projectId: this.projectId,
                            idcard: this.addform.certificatesCode,
                            mobile: this.addform.mobile,
                            userName: this.addform.name,
                            attributes: []
                        }
                        this.attributes.forEach((e,i)=>{

                            let item = {
                                attributeId: e.attributeId,
                                attributeValue: ''
                            }
                            if(e.attributeValue&&e.attributeType == 7){
                                item.attributeValue = parseFloat(e.attributeValue)
                            }else if(e.attributeValue&&e.attributeType == 2){
                                item.attributeValue = e.fileKey
                            }else{
                                item.attributeValue = e.attributeValue
                            }
                            params.attributes.push(item)
                        })
                        console.log(params)
                        this.$post("/biz/apply/user/addUser",params,3000,true,6).then((ret) => {
                            if(ret.status == 0){

                                this.$message.success('新增学员成功！')

                                this.getListData();
                                this.closeAddDialog()
                            }
                        });
                    }
            })


        },
        /* 导入 - 点击导入 */
        Exportstudent() {
            const {projectId}=this;
            this.$refs.accreditationAgencyImport.showPopup(projectId)
        },
        /* 导入 - 导入回调 */
        accreditationAgencyImportBack(){
            this.getListData();
        },

        /* 导入材料 - 点击导入 */
        ExportsMaterials() {
            const {projectId}=this;
            this.$refs.importMaterials.showPopup(projectId)
        },
        /* 导入材料 - 导入回调 */
        importMaterialsBack(){
            this.getListData();
        },

        //码值获取信息
        getCrowdtypeList() {
            const evaluateIdTypeList = this.$setDictionary(
                "EVALUATE_ID_TYPE",
                "list"
            );
            const evaluateEducationList = this.$setDictionary(
                "EDUCATION",
                "list"
            );

            const list = [],
                educationList = [];
            for (const key in evaluateIdTypeList) {
                list.push({
                    value: key,
                    label: evaluateIdTypeList[key],
                });
            }
            for (const key in evaluateEducationList) {
                educationList.push({
                    value: key,
                    label: evaluateEducationList[key],
                });
            }

            this.evaluateIdTypeList = list;
            this.educationList = educationList;
        },
        /* 申报条件 -- 下拉 */
        // getOccupationConditionListDropDown(planId) {
        //     this.$post(
        //         "/biz/evaluate/evaluateCompanyOccupationCondition/occupationConditionListDropDown",
        //         { planId }
        //     ).then((res) => {
        //         this.conditionIdTypeList = res.data || [];
        //     });
        // },

        conditionChange(val) {
            this.conditionIdTypeList.map((el) => {
                if (el.conditionId == val) {
                    this.evaluateMaterials = el.evaluateMaterials;
                }
            });
        },
        //身份证人像面图片上传
        handleAvatarSuccessCardFront(res) {
            let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
            let formData = new FormData();
            formData.append("folder ", "COURSE_WARE");
            formData.append("file ", res.raw);
            formData.append("fileType ", extension);
            this.$Postformat("/sys/upload", formData)
                .then((result) => {
                    this.baseform.idCardFront = result.data.fileURL;
                    this.baseform.idCardFrontPath = result.data.fileKey;
                })
                .catch(() => {
                    setTimeout(() => {
                        this.$message({
                            type: "warning",
                            message: "上传失败",
                        });
                    }, 300);
                });
        },
        //身份证国徽面图片上传
        handleAvatarSuccessCardBack(res) {
            let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
            let formData = new FormData();
            formData.append("folder ", "COURSE_WARE");
            formData.append("file ", res.raw);
            formData.append("fileType ", extension);
            this.$Postformat("/sys/upload", formData)
                .then((result) => {
                    this.baseform.idCardBack = result.data.fileURL;
                    this.baseform.idCardBackPath = result.data.fileKey;
                })
                .catch(() => {
                    setTimeout(() => {
                        this.$message({
                            type: "warning",
                            message: "上传失败",
                        });
                    }, 300);
                });
        },
        //图片上传
        handleAvatarSuccess2(res) {
            let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
            let formData = new FormData();
            formData.append("folder ", "USER");
            formData.append("file ", res.raw);
            formData.append("fileType ", extension);
            this.$Postformat("/sys/upload/image/compress", formData)
                .then((result) => {
                    this.baseform.headPathUrl = result.data.fileURL;
                    this.baseform.headPath = result.data.fileKey;
                })
                .catch(() => {
                    setTimeout(() => {
                        this.$message({
                            type: "warning",
                            message: "上传失败",
                        });
                    }, 300);
                });
        },

        // 列表序号初始化
        indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
        },
        // 初始化数据
        initData(){
            // 处理上一页面路由传参（必须先执行这行代码）
            this.projectId=this.$route.query.projectId;
            this.searchForm.projectId=this.$route.query.projectId;
            this.projectName=this.$route.query.projectName;
            this.channel=this.$route.query.channel|| false;
            if(this.$route.query.isStatistics){
                this.isStatistics=this.$route.query.isStatistics;
                this.compName=this.$route.query.compName;
                this.compId=this.$route.query.compId;
            }

            // 获取列表数据
            this.getListData();
            // 获取招生渠道数据
            this.getRecruitmentChannels();
            this.getAuditList();
            // 获取证件类型、文化程度码值
            this.getCrowdtypeList()
            // 获取申报条件下拉
            // this.getOccupationConditionListDropDown()

            // this.getTemplate(this.projectId);

            // 新增用
            this.selectGetList();
            this.getareatree();
            this.getInvoice_type();
            this.getDeclarationConditions();
        },

        //审核状态
        getAuditList() {
            const auditStateList = this.$setDictionary("CT_USER_AUDIT_STATE", "list");
            const list = [];
            for (const key in auditStateList) {
                list.push({
                    value: key,
                    label: auditStateList[key]
                });
            }
            this.auditStateList = list;
        },
        // 获取列表数据
        getListData(current){
            console.log(this.isStatistics)
            const {searchForm,pagination,projectId}=this;
            const params=JSON.parse(JSON.stringify(searchForm));
            // 处理自招渠道数据
            if(params.channelId==="") delete params.confessMark;
            if(params.channelId==="noId") delete params.channelId;
            if(current) this.pagination.current=current;
            let url = "/biz/ct/user/ctProjectUserList"
            if(this.isStatistics){
                url = "/ct/operation/apply/user/page"
            }
            this.$post(url,{
                pageNum:pagination.current,
                pageSize:pagination.size,
                projectId,
                ...params
            }).then(res=>{
                this.listData=res?.data?.list||[];
                pagination.total=res?.data?.total||0;
            }).catch(e=>{
                console.error("获取列表数据出错",e);
            });
        },
        // 导出
        exportData(){
            const {projectId}=this;
            const params=JSON.parse(JSON.stringify(this.searchForm));
            // 处理自招渠道数据
            if(params.channelId==="") delete params.confessMark;
            if(params.channelId==="noId") delete params.channelId;

            let url = "/biz/ct/user/ctProjectUserListExport"
            if(this.isStatistics){
                url = "/ct/operation/apply/user/export"
            }
            this.$post(url,{
                ...params,
                projectId
            }).then(res=>{
                const arr=[res.data];
                for (let item of arr) {
                    if (!this.downloadItems.includes(item.taskId)) {
                        this.$store.dispatch("pushDownloadItems", item.taskId);
                    } else {
                        this.$message.warning("[" + item.fileName + "]已经申请下载,请耐心等待");
                    }
                }
            }).catch(e=>{
                console.error("导出出错",e);
            });
        },
        // 编辑学员
        editStudent(row, $index){
            const {projectId, projectName}=this;
            const {userId}=row;
            this.$router.push({
                path:"/web/studentUpdate",
                query:{
                    projectId,
                    projectName,
                    userId,
                    // 当前数据下标，从1开始
                    // index: (this.pagination.current - 1) * this.pagination.size + $index + 1,
                    // total: this.pagination.total,
                    searchForm: JSON.stringify(this.searchForm),
                    // origin:this.isStatistics?"鉴定报名统计":'报名',
                    // type: "depart",
                    // isStatistics: this.isStatistics
                }
            });
        },
        // 删除学员
        deleteStudent(row){
            const {projectId}=this;
            const {userId}=row;
            this.$confirm('确认删除该学员吗？删除后不可恢复！', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                confirmButtonClass:"bgc-bv",
            }).then(() => {
                this.$post("/biz/ct/user/deleteCtProjectUser",{
                    projectId,
                    userId
                }).then(()=>{
                    this.getListData();
                    this.$message.success("删除成功");
                });
            });
        },
        // 查看详情
        toDetail(row, $index){
          if (this.channel) {
            const {userId,projectId}=row;
            this.$router.push({
              path:"/web/studentDetails",
              query:{
                projectId,
                userId,
                // 当前数据下标，从1开始
                index: (this.pagination.current - 1) * this.pagination.size + $index + 1,
                total: this.pagination.total,
                searchForm: JSON.stringify(this.searchForm),
                origin:"渠道报名学员管理",
                type: "channel",
              }
            });
          } else {
            const {projectId, projectName}=this;
            const {userId}=row;
            this.$router.push({
              path:"/web/studentDetails",
              query:{
                projectId,
                projectName,
                userId,
                // 当前数据下标，从1开始
                index: (this.pagination.current - 1) * this.pagination.size + $index + 1,
                total: this.pagination.total,
                searchForm: JSON.stringify(this.searchForm),
                origin:this.isStatistics?"鉴定报名统计":'报名',
                type: "depart",
                isStatistics: this.isStatistics
              }
            });
          }
        },
        // 获取招生渠道数据
        getRecruitmentChannels(){
            console.log('compId ',this.compId)
            let url = "/biz/ct/channel/ctChannelSelectList"
            let params = {}
            if(this.isStatistics){
                url = "/ct/operation/apply/comp/channel/list"
                params.compId = this.compId
            }
            this.$post(url, params).then(res => {
                this.recruitmentChannelsList = res.data || [];
                this.recruitmentChannelsList.unshift({
                    contactUser: "自招",
                    confessMark: "10",
                    channelId: "noId"
                });
            }).catch(e => {
                console.error("获取招生渠道数据出错", e);
            });
        },        
        setAddAndEdit(row, $index){
            this.cancelData();
            this.addAndEditData.projectApplyIdList.push(row.projectApplyId);
            this.addAndEditData.applyState = row.applyState;
            this.addAndEditData.dialogVisible = true;
        },
        // 新增&编辑 - 弹框-取消
        cancelData() {
            this.addAndEditData = this.$options.data().addAndEditData;
        },
        // 新增&编辑 - 弹框-确认
        saveData() {
        this.$refs['addAndEditData'].validate((valid) => {
            if (valid) {
            this.$post("/biz/ct/user/apply/schedule/update", {
                projectApplyIdList: this.addAndEditData.projectApplyIdList,
                applyState: this.addAndEditData.applyState
            }).then((res) => {
                this.cancelData();
                this.getListData();
                this.$message.success("操作成功");
            }).catch(() => {
                return;
            });
            }
        });
        },
        // 每页条数改变
        sizeChange(size){
            this.pagination.size=size;
            this.getListData();
        },
        // 当前页改变
        currentChange(current){
            this.pagination.current=current;
            this.getListData();
        },
        // 设置表格高度
        getTableHeight(opDom = false, page = true) {
            let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
            if (opDom)  tHeight -= 40 + 0.675 * 16 + 1;
            if (page)  tHeight -= 50;
            this.tableHeight = tHeight;
        },


        // /* 上传材料 */
        // // 打开弹窗
        // ExportsMaterials(){
        //     this.materialsDialogVisible = true
        // },
        // // 弹窗关闭
        // materialsHandleClose(){
        //     this.materialsDialogVisible = false
        // },
        // // 保存当前index
        // saveIndex(i){
        //     this.currentIndex = i
        // },
        // // 上传文件
        // materialsUploadChange(file, fileList,e){
        //     console.log(file)
        //     this.materials[this.currentIndex].name = file.name
        //     let size = file.size / 1024 / 1024;
        //     // let fileName = file.name.split(".");
        //     let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        //     const isZIP = extension === "ZIP";
        //     const iszip = extension === "zip";
        //     if (!isZIP && !iszip) {
        //         this.$message.error("只能上传后缀是.zip的文件");
        //         return;
        //     }
        //     if (size > 3) {
        //         this.$message.error("文件大小不能超过3M");
        //         return;
        //     }
        //     let formData = new FormData();
        //     formData.append("folder ", "USER");
        //     formData.append("file ", file.raw);
        //     formData.append("fileType ", extension);
        //
        //     this.$Postformat("/sys/upload", formData)
        //         .then((result) => {
        //             // console.log(result);
        //             this.materials[this.currentIndex].fileKey = result.data.fileKey || ""
        //             this.materials[this.currentIndex].fileUrl = result.data.fileURL || "";
        //             // this.fileName = result.data.fileKey;
        //         })
        //         .catch((err) => {
        //             return;
        //         });
        // },
    },
    watch:{
        $route:{
            handler(to,from){
                if(from.path==="/web/studentDetails") {
                    this.getListData();
                }
                if(from.path==="/web/studentUpdate") {
                    this.getListData();
                }
                if(to.path==="/web/customProgramsRegistrationList"){
                    from.meta.state=false;
                }
            }
        }
    },
    created(){
        this.initData();

        // this.getCertificatesType()
    },
    beforeRouteLeave(to, from, next) {
        if (to.meta.level == 1) {
            from.meta.state = true;
            if (this.$vnode.parent) {
                if (to.name != 'login') {
                    this.$vnode.parent.componentInstance.cache = {};
                    this.$vnode.parent.componentInstance.keys = [];
                    this.$destroy()
                }
            }
        } else{
            from.meta.state = true;
        }
        next();
    }
}
</script>
<style lang="less" scoped>
/deep/.el-input-number.is-without-controls .el-input__inner{
    text-align: left;
}
/deep/.el-input--suffix .el-input__inner{
    padding-right: 15px;
}
.PImgtitle {
    text-align: center;
    max-width: 286px;
    color: #2878ff;
}
.hasRequired /deep/.el-form-item__label:before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
}
.addDialog /deep/.el-form--label-left .el-form-item__label{
    padding: 10px 0;
    min-height: 40px;
    line-height: 20px;
}
</style>
<style>
.my-select .el-select-dropdown__item {
    overflow: visible;
    display: block;
    min-height: 34px;
    height: auto;
    line-height: 20px;
    padding: 7px 20px;
    word-break:break-all;
    word-wrap:break-word;
    white-space: inherit;

}

</style>
